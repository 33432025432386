<template>
  <b-card
    v-if="dataFormCard"
    no-body
    class="card-developer-meetup"
  >
    <div class="bg-light-primary rounded-top text-center">
      <b-img
        :src="require('@/assets/images/illustration/api.svg')"
        alt="Meeting Pic"
        height="170"
      />
    </div>
    <b-card-body>
      <div class="meetup-header d-flex align-items-center">
        <div class="my-auto">
          <b-card-title class="mb-25">
            {{ admissionFormTitle }}
          </b-card-title>
          <b-card-text class="mb-0">
            {{ admissionFormSubTitle }}
          </b-card-text>
        </div>
      </div>
      <b-media
        v-for="media in dataFormCard.mediaData"
        :key="media.avatar"
        no-body
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              :icon="media.avatar"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ media.title }}
          </h6>
          <small>{{ media.subtitle }}</small>
        </b-media-body>
      </b-media>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatar, VBTooltip, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataFormCard: {
      type: Object,
      default: () => {},
    },
    admissionFormTitle: {
      type: String,
      default: '',
    },
    admissionFormSubTitle: {
      type: String,
      default: '',
    }
  },
}
</script>
